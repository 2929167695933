.date-time-picker-container {
  position: relative;
  width: 100%;

  .picker-input-container {
    width: 100%;
  
    .clock-icon {
      float: right;
      position: relative;
      margin-top: -40px;
      right: 16px;
    }
  }
  
  .picker-options-container {
    display: flex;
    position: absolute !important;
    justify-content: center;
    overflow: hidden;
    width: 227px;
    background-color: white;
    border-radius: 4px;
    box-shadow: rgb(148, 148, 148) 0px 0px 0px 1px inset;
    mask-image: none !important;
    z-index: 1002;
  
    .selected-value {
      opacity: 1;
    }
  
    .unselected-value {
      opacity: 0.3;
    }
  }
}

