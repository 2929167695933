/*---- START: Visualizations styles ----*/

.visualizations-container {
  display: flex;
  width: 949px;
  flex-direction: row;
  align-items: flex-start;
  gap: 7px;
  border-radius: 0px 0px 8px 8px;
  background-color: #f2f2f2;
  padding-bottom: 5px;
}

.svg-clickable-area {
  padding: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  touch-action: none;
}

/* START: Cervix descent visualization */

.descent-visualization-main-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 470px;
  height: 490px;

  .title {
    color: #767676;
    font-size: 14px;
    margin: 3px 0px 0px 10px;
  }

  .inner-container {
    width: 470px;
    height: 470px;
    border-radius: 8px;
    border: 1px #ccc solid;
    background-color: #fff;
    display: grid;
    gap: 9px;

    .top-bar-container {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      .dropdown-container {
        display: flex;
        width: 223px;
        padding: 8px 8px 4px 0px;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
      }
    }
  }
}

/* END: Cervix descent visualization */

/* START: Rotation visualization */

.rotation-visualization-main-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 470px;
  height: 490px;

  .title {
    color: #767676;
    font-size: 14px;
    margin: 3px 0px 0px 10px;
  }

  .inner-container {
    width: 470px;
    height: 470px;
    border-radius: 8px;
    border: 1px #ccc solid;
    background-color: #fff;
    display: grid;
    gap: 9px;

    .top-bar-container {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      .dropdown-container {
        display: flex;
        width: 223px;
        padding: 8px 8px 4px 0px;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
      }
    }
  }
}

.rotation-area {
  margin-left: 2px;
}

/* END: Rotation visualization */

/*---- END: Visualizations styles ----*/
