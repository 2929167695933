.new-patient-bar-container {
  display: flex;
  width: 950px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  .bottom-bar-container {
    width: 946px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
