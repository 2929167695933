/* START: Calculator Section */

.calculator-container {
  display: flex;
  width: 950px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  margin-bottom: 10px;

  .main-calculator-input-container {
    width: 100%;
    display: flex;
    justify-content: left;
    gap: 10px;

    .main-input-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .input-container {
      display: flex;
      width: 230px;
      padding-bottom: 4px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      flex-shrink: 0;
    }

    .button-container {
      width: 230px;
      height: 64px;
      margin-top: 15px;
      display: flex;
      gap: 10px;

      .label {
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 1px;
      }
    }

    .input-title {
      color: #767676;
      font-size: 14px;
    }
  }

  .calculator-result-container {
    display: flex;
    padding: 16px;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 2px;
    background-color: #d4e9f2;
    gap: 10px;
    color: #215369;

    .main-title {
      font-size: 32px;
      margin-top: 6px;
    }

    .delayed-progress-time-value {
      width: 230px;
      flex-shrink: 0;
      text-align: center;
      font-size: 45px;
      font-weight: 700;
    }

    .more-details {
      width: 168px;
      flex-shrink: 0;
      font-size: 16px;
      margin-top: 10px;
      line-height: 20px;

      .latest-time-registered-value,
      .cervix-opening-value {
        font-weight: bold;
      }
    }
  }

  .container-separator {
    width: 950px;
    height: 3px;
    background-color: #8a7e7e;
    margin: 24px 0;
  }

  .bottom-bar-container {
    width: 950px;
    margin-top: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

/* END: Calculator Section */
