.warning-values-main-container  {
  width: 950px;
  display: flex;
  justify-content: flex-start;
}

.warning-values-inner-container {
  padding: 16px;
  margin: 8px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  margin-top: -45px;
}

.warning-title {
  display: block;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
}

.stage-section {
  margin-top: 12px;
}

.stage-title {
  display: block;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
}

.stage-section ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.stage-section li {
  margin: 8px 0;
} 