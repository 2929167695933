/* Fonts Style */

@font-face {
  font-family: Lato;
  src: local("Lato"), url(resources/fonts/Lato-Regular.ttf) format("truetype");
}
@font-face {
  font-family: Lato;
  src: local("LatoBold"),
    url(resources/fonts/Lato-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("LatoBoldItalic"),
    url(resources/fonts/Lato-Regular.ttf) format("truetype");
}

/* NORWELCG STYLES */

.main {
  background-color: #f2f2f2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-container {
  display: flex;
  width: 100%;
  padding-bottom: 25px;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;

  .tab-group-container {
    display: flex;
    width: 950px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
}

.app-overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: #000;
  opacity: 0.5;
  z-index: 1000;
}

.z-index-to-top {
  z-index: 1001;
}
/* START: Top Navigation Bar */

header {
  position: sticky;
  top: 0;
  z-index: 1020;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px 10px 20px;
  height: 24px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

  .title-container {
    display: flex;
    padding-left: 8px;
    align-items: center;
    gap: 8px;
    color: #276d8b;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }

  .info-container {
    display: flex;
    color: #276d8b;
    margin-left: auto;
    cursor: pointer;
  }
}

/* END: Top Navigation Bar */

/* START: Style for the modal windows */

.tidskpunt-confirmation-window-body,
.info-box-modal-body {
  color: #1f1f1f;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.tidspunkt-confirmation-window-button-text {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;

  .slow-progress-time {
    font-weight: bold;
    font-size: 18px;
  }
}

/* END: Style for the modal windows */

/*---- START: Error Component Styling */

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  height: 60vh;
}

.error-page .error-code {
  font-size: 150px;
  font-weight: 600;
  color: #00a8ff;
}

.error-page .error-text {
  font-size: 34px;
  font-weight: 600;
  color: #000;
}

/*---- START: Rotate card styling */

.rotate-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 auto;
  width: 50%;
}

.rotate-card-overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: #000;
  opacity: 0.5;
}

/*---- END: Rotate card styling */
