/*---- START: Action bar styles ----*/

.actionbar-container {
  display: flex;
  width: 950px;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  margin-left: 5px;

  .button-container {
    display: flex;
    height: 48px;
    min-width: 64px;
    padding: 4px 0px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .progress-player-container {
    width: 630px;
    height: 61px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px #ccc solid;
    background-color: #fff;
  }
}

.register-data-form-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .input-title {
    color: #767676;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .timepicker-container {
    width: 73%;
    float: left;
  }
  .toggle-container {
    width: 25%;
    float: right;
    padding-right: 2px;
  }
}

.view-data-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  table {
    overflow: hidden;
  }

  /* CSS class to add a bottom border to the table after the footer is removed */
  div.border {
    border-bottom: 1px solid #cccccc;
  }
}

/*---- END: Action bar styles ----*/
